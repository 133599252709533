// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (app: string): VerticalNavItemsType => {
  if (app === 'public') {
    return [
      {
        title: 'Dashboard',
        subject: 'dashboard',
        icon: 'mdi:home-outline',
        path: '/welcome'
      },
      {
        sectionTitle: 'Public Widget'
      },
      {
        title: 'Widget',
        subject: 'widget',
        icon: 'mdi:chat-processing-outline',
        path: '/apps/public/widget'
      },
      {
        title: 'History',
        subject: 'widget',
        icon: 'mdi:history',
        path: '/apps/public/history'
      },
      {
        title: 'Analytics',
        subject: 'widget',
        icon: 'mdi:report-areaspline',
        path: '/apps/public/report'
      },
      {
        sectionTitle: 'Manage Data'
      },
      {
        title: 'Content',
        subject: 'content',
        icon: 'mdi:file-code-outline',
        path: '/apps/data'
      },
      {
        title: 'Products',
        subject: 'product',
        icon: 'mdi:shopping-search-outline',
        path: '/apps/products'
      },
      {
        subject: 'setup',
        sectionTitle: 'Integration'
      },
      {
        title: 'Agents',
        subject: 'setup',
        icon: 'mdi:person-circle-outline',
        path: '/apps/chat/prompt'
      },
      {
        title: 'Agent Tools',
        subject: 'setup',
        icon: 'mdi:connection',
        path: '/apps/data/plugin'
      },
      // {
      //   title: 'Agent Workflows',
      //   subject: 'setup',
      //   icon: 'mdi:workflow-outline',
      //   path: '/apps/workflow'
      // },
      {
        title: 'Tool Builder',
        subject: 'setup',
        icon: 'mdi:toolbox-outline',
        path: '/apps/data/toolbuilder'
      },
      {
        title: 'Channels',
        subject: 'setup',
        icon: 'mdi:shopping-outline',
        path: '/apps/products/setup'
      }
    ]
  } else if (app === 'internal') {
    return [
      {
        title: 'Dashboard',
        subject: 'dashboard',
        icon: 'mdi:home-outline',
        path: '/welcome'
      },
      {
        subject: 'chat',
        sectionTitle: 'Internal Assist'
      },
      {
        title: 'Chat',
        subject: 'chat',
        icon: 'mdi:message-outline',
        path: '/apps/chat'
      },
      {
        title: 'History',
        subject: 'chat',
        icon: 'mdi:history',
        path: '/apps/chat/history'
      },
      {
        title: 'Voice (Beta)',
        subject: 'chat',
        icon: 'mdi:account-voice',
        path: '/apps/voice'
      },
      {
        title: 'Planner (Beta)',
        subject: 'chat',
        icon: 'mdi:face-agent',
        path: '/apps/chat/agent'
      },
      {
        sectionTitle: 'Manage Data'
      },
      {
        title: 'Content',
        subject: 'content',
        icon: 'mdi:file-code-outline',
        path: '/apps/data'
      },
      {
        title: 'Products',
        subject: 'product',
        icon: 'mdi:shopping-search-outline',
        path: '/apps/products'
      },
      {
        subject: 'setup',
        sectionTitle: 'Integration'
      },
      {
        title: 'Agents',
        subject: 'setup',
        icon: 'mdi:person-circle-outline',
        path: '/apps/chat/prompt'
      },
      {
        title: 'Agent Tools',
        subject: 'setup',
        icon: 'mdi:connection',
        path: '/apps/data/plugin'
      },
      // {
      //   title: 'Agent Workflows',
      //   subject: 'setup',
      //   icon: 'mdi:workflow-outline',
      //   path: '/apps/workflow'
      // },
      {
        title: 'Tool Builder',
        subject: 'setup',
        icon: 'mdi:toolbox-outline',
        path: '/apps/data/toolbuilder'
      },
      {
        title: 'Channels',
        subject: 'setup',
        icon: 'mdi:shopping-outline',
        path: '/apps/products/setup'
      }
    ]
  } else if (app === 'translations') {
    return [
      // {
      //   title: 'Translations Home',
      //   subject: 'home',
      //   icon: 'mdi:home-outline',
      //   path: '/translations'
      // },
      {
        title: 'Translate',
        subject: 'single',
        icon: 'mdi:translate',
        path: '/translations'
      },
      {
        title: 'Products',
        subject: 'products',
        icon: 'mdi:shopping-outline',
        path: '/translations/products'
      },
      {
        title: 'Metaobjects',
        subject: 'metaobjects',
        icon: 'mdi:card-multiple-outline',
        path: '/translations/metaobjects'
      },
      {
        title: 'Pages',
        subject: 'pages',
        icon: 'mdi:file-document-outline',
        path: '/translations/pages'
      },
      {
        title: 'Blogs',
        subject: 'blogs',
        icon: 'mdi:blogger',
        path: '/translations/blogs'
      },
      {
        title: 'Articles',
        subject: 'articles',
        icon: 'mdi:book-open-page-variant',
        path: '/translations/articles'
      },
      {
        title: 'Templates',
        subject: 'templates',
        icon: 'mdi:palette-outline',
        path: '/translations/templates'
      },
      {
        title: 'Mapped Translations',
        subject: 'translations',
        icon: 'mdi:transit-connection-horizontal',
        path: '/translations/translations'
      },
      {
        title: 'Translation Agent',
        subject: 'setup',
        icon: 'mdi:account-voice',
        path: '/translations/prompt'
      },
      {
        title: 'Compare HTML',
        subject: 'compare',
        icon: 'mdi:code-tags',
        path: '/translations/html_compare'
      }
    ]
  } else {
    return [
      {
        title: 'Dashboard',
        subject: 'dashboard',
        icon: 'mdi:home-outline',
        path: '/welcome'
      }
    ]
  }
}

export default navigation
